import React, { useState } from "react";
import * as C from "./styles";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth"; 
import { LoginUserCommand, UsersService } from 'api-gen';
import { useDesignParams } from "contexts/DesignParamsContext";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
const Signin = () => {
  const { user, signin } = useAuth();

  const  designParams  = useDesignParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");

  console.log('user', user)
  const handleLogin = async () => {
    if (!email || !senha) {
      setError("Preencha todos os campos");
      return;
    }

    try {

      const body : LoginUserCommand = {
        email: email,
        password: senha
      }; 
      const login = await UsersService.putApiUsersLogin(body);
      if (login.loginUserViewModel) {
        signin(login.loginUserViewModel);
      } else {
        console.log("login:", login)
        setError(login);
        return;
      }

    } catch (e) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      setError(e?.body?.message ?? "Algum erro inesperado aconteceu.");
      return;
    }

    navigate("/home");
  };

  return (
    <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "2rem",	
      height: "100vh",
      backgroundColor: designParams?.primaryColorHex || '',
    }}>
      {
        designParams && designParams?.logo ? <img style={{maxWidth: "12rem"}} alt="Logo" src={designParams.logo} />
        :  <C.Label>EXAM PLATFORM</C.Label>
      } 
       <Box
                sx={{
                    backgroundColor: "#fff",
                    padding: "2rem",
                    borderRadius: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "25rem",

                }}
            >
                <Typography variant="h6">Login</Typography>
        <TextField
          type="email"
          placeholder="Digite seu E-mail"
          value={email}
          sx={{ width: "75%" }}
          onChange={(e) => [setEmail(e.target.value), setError("")]}
        />
        <TextField
          type="password"
          placeholder="Digite sua Senha"
          value={senha}
          sx={{ width: "75%" }}
          onChange={(e) => [setSenha(e.target.value), setError("")]}
        />
        {
            error &&
            <Alert severity="error" sx={{ width: "75%" }}>
              {error}
            </Alert>
        }
        <Button
        variant='contained'
        sx={{ backgroundColor: designParams?.primaryColorHex || '',
          width: "75%",
         }}
        onClick={handleLogin}>
           Entrar
        </Button>
        <C.LabelSignup>
          Não tem uma conta?
          <C.Strong>
            <Link to="/signup">&nbsp;Registre-se</Link>
          </C.Strong>
        </C.LabelSignup>
        <Button component={RouterLink} to="/recuperarsenha" sx={{width: '80%'}}>Esqueci minha senha</Button>
      </Box> 
    </div>
  );
};

export default Signin;
