
import axios from 'axios';

// import { responseInterceptor, errorInterceptor } from './interceptors';
//import { responseInterceptor, errorInterceptor } from './interceptors/index';

const Api = axios.create({
    //baseURL: "https://localhost:44360/api",
    baseURL: 'https://sinapse-api.azurewebsites.net/api'
});

// Api.interceptors.response.use(
//   (response) => responseInterceptor(response),
//   (error) => errorInterceptor(error),
// );

Api.interceptors.request.use((request) => {
    const token = localStorage.getItem("token");

    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
});


export { Api };
